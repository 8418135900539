import { graphql } from "gatsby"
import React from "react"
import { Col, Container, Row, media } from "styled-bootstrap-grid"
import styled from "styled-components"

import {
  Card,
  Gallery,
  Image,
  Layout,
  LinkButton,
  MediaObjectGrid,
  Menu,
  Paragraph,
  ReviewBlock,
  SEO,
  SocialMedia,
} from "../../components"
import ArrowDownWhite from "../../images/Arrow_Down_White.svg"
import ArrowRightRed from "../../images/Arrow_Right_Red.svg"
import ArrowRight from "../../images/arrow-right.svg"
import { Colors } from "../../utils/constants"

const Careers = ({ data }) => {
  const topCards = data.topCards.nodes
  const postInPage = data.postInPage.nodes
  const socialMedias = data.socialMedias.nodes[0]
  const jobOpportunity = data.jobOpportunity.nodes[0]
  const headerBg = data.galleryInPage.nodes[0].media[0]
  const headerBg2 = data.galleryInPage.nodes[0].media[1]
  const benefits = data.benefit.nodes[0]
  const jobs = jobOpportunity.jobs.map(({ title, slug }) => ({
    label: title,
    href: "/careers/" + slug,
    icon: ArrowRight,
  }))
  const ReviewsData = {
    title: data.reviewInPage.nodes[0].title,
    reviews: data.reviewInPage.nodes[0].reviews.map(review => ({
      content: {
        name: review.author,
        text: review.content.content,
        position: review.title,
        slug: review.slug,
      },
      fixedSources: review.thumbnail?.localFile?.childImageSharp,
    })),
  }
  const peakIntoADayGalleryData = {
    paragraph: {
      title: data.galleryInPage.nodes[1].title,
      content: data.galleryInPage.nodes[1].content.content,
    },
    photos: data.galleryInPage.nodes[1].media.map(
      photo => photo.localFile?.childImageSharp
    ),
  }
  const growAndExpandGalleryData = {
    paragraph: {
      title: data.galleryInPage.nodes[2].title,
      content: data.galleryInPage.nodes[2].content.content,
    },
    photos: data.galleryInPage.nodes[2].media.map(photo => ({
      fixedSources: photo?.localFile?.childImageSharp,
      ratios: Math.random() * 0.25 + 0.2,
    })),
    isVertical: false,
  }
  const foodGalleryData = {
    paragraph: {
      title: data.galleryInPage.nodes[3].title,
      content: data.galleryInPage.nodes[3].content.content,
    },
    photos: data.galleryInPage.nodes[3].media.map(photo => ({
      fixedSources: photo.localFile?.childImageSharp,
      ratios: Math.random() * 0.25 + 0.25,
    })),
    isVertical: true,
  }
  return (
    <Layout>
      <SEO title="Join us" />
      {/* landing section */}
      <StyledTopImage fluid>
        {headerBg && (
          <Image
            fluid={headerBg.localFile?.childImageSharp.fluid}
            alt="background"
          />
        )}
        {headerBg2 && (
          <Image
            fluid={headerBg2.localFile?.childImageSharp.fluid}
            alt="background"
          />
        )}
      </StyledTopImage>
      <Container>
        <Row>
          {/* first raw */}
          <Col col="12" mdOrder="1" order="2">
            <StyledHeaderContainer>
              <Row justifyContent="space-between">
                <StyledCardCol md="6" col="12">
                  <StyledLink href={topCards[0].link.url}>
                    <StyledCard>
                      <Card
                        description={topCards[0].content.content}
                        title={topCards[0].title}
                        childrenPosition="flex-end"
                        textColor={Colors.WHITE}
                        titleColor={Colors.WHITE}
                        backgroundColor={Colors.RED}
                        isNarrow
                      >
                        <img src={ArrowDownWhite} alt="" />
                      </Card>
                    </StyledCard>
                  </StyledLink>
                </StyledCardCol>
                <StyledCardCol md="6" col="12">
                  <StyledLink href={topCards[1].link.url}>
                    <StyledCard>
                      <Card
                        description={topCards[1].content.content}
                        title={topCards[1].title}
                        childrenPosition="flex-end"
                        backgroundColor={Colors.WHITE}
                        isNarrow
                      >
                        <img src={ArrowRightRed} alt="" />
                      </Card>
                    </StyledCard>
                  </StyledLink>
                </StyledCardCol>
              </Row>
            </StyledHeaderContainer>
          </Col>
          {/* second row JS specialists */}
          <Col col="12" mdOrder="2" order="1">
            <StyledTopParagraph>
              <Row justifyContent="center">
                <Col col="12" md="10">
                  <Paragraph
                    title={postInPage[0].title}
                    content={postInPage[0].content.content}
                    centerH
                  />
                </Col>
              </Row>
            </StyledTopParagraph>
          </Col>
        </Row>
      </Container>
      {/* peak section */}
      <ContainerWithVerticalMargin top fluid>
        <StyledPeakContainer>
          <BlackBackground />
          <Col col="12" md="7">
            <Paragraph {...peakIntoADayGalleryData.paragraph} />
          </Col>
          <Col col="12" md="10">
            <PeakImages>
              {peakIntoADayGalleryData.photos.map((photo, index) => (
                <Image key={index} fixedSources={photo} alt="team-images" />
              ))}
              <LinkButton href={data?.links.nodes[1].url} theme="red">
                {data?.links.nodes[1].content}
              </LinkButton>
            </PeakImages>
          </Col>
        </StyledPeakContainer>
      </ContainerWithVerticalMargin>
      {/* Develop awesomeness */}
      <ContainerWithVerticalMargin top bottom>
        <Row>
          <Col col="12">
            <StyledDevelopAwesomenessParagraph
              title={postInPage[1].title}
              content={postInPage[1].content.content}
              fixedSources={postInPage[1].thumbnail?.localFile?.childImageSharp}
              isReversed
              mobile={{ isReversed: true }}
              centerV
              imageNotFullWidthOnMobile
            />
          </Col>
        </Row>
      </ContainerWithVerticalMargin>
      {/* benefits section */}
      <Container>
        <MediaObjectGrid
          mediaObjects={benefits.mediaObject.map(
            ({ title, content, icon }) => ({
              titleColor: Colors.RED,
              icon: icon.file.url,
              title: title,
              description: content.content,
            })
          )}
          paragraph={{
            title: benefits.title,
            content: benefits.content.content,
          }}
          link={{
            label: benefits.link.content,
            href: benefits.link.url,
            theme: "red",
          }}
          col="3"
        />
      </Container>
      {/* grow section */}
      <ContainerWithVerticalMargin top fluid>
        <Container>
          <Row>
            <Col col="12">
              <Gallery {...growAndExpandGalleryData} centerV />
            </Col>
          </Row>
        </Container>
      </ContainerWithVerticalMargin>
      {/* our technologies section */}
      <ContainerWithVerticalMargin>
        <Paragraph
          title={postInPage[2].title}
          content={postInPage[2].content.content}
          link={{
            href: postInPage[2].link.url,
            label: postInPage[2].link.content,
          }}
          fixedSources={postInPage[2].thumbnail.localFile.childImageSharp}
          mobile={{ isReversed: true }}
        />
      </ContainerWithVerticalMargin>

      {/* team saying section */}
      <ContainerWithVerticalMargin top>
        <Row>
          <Col col="12">
            <ReviewBlock {...ReviewsData} />
          </Col>
        </Row>
      </ContainerWithVerticalMargin>
      {/* openings section */}
      <ContainerWithVerticalMargin
        top
      >
      <Anchor id={topCards[0].link.url.replace("#", "")}/>
        <Paragraph title={jobOpportunity.title} />
        <Row>
          <Col hiddenMdDown>
            <Paragraph content={jobOpportunity.content.content} />
          </Col>
        </Row>
        <Row alignItems="center">
          <Col col="12" lg="8">
            <Menu items={jobs} stretched isVertical theme="list" />
            {/*
            <LinkButton
              theme="inverted"
              stretched
              href={jobOpportunity.link.url}
            >
              {jobOpportunity.link.content}
            </LinkButton>
            */}
          </Col>
          <JobCardCol col="12" lg="4">
            <Card
              description={jobOpportunity.jobCard.content.content}
              title={jobOpportunity.jobCard.title}
              isVertical
            >
              <LinkButton theme="red" href={jobOpportunity.jobCard.link.url}>
                {jobOpportunity.jobCard.link.content}
              </LinkButton>
            </Card>
          </JobCardCol>
        </Row>
      </ContainerWithVerticalMargin>
      {/* we love food section */}
      <ContainerWithVerticalMargin top fluid>
        <Container>
          <Row>
            <Col col="12">
              <Gallery {...foodGalleryData} alignRight />
            </Col>
          </Row>
        </Container>
      </ContainerWithVerticalMargin>
      {/* social media section */}
      <SocialMedia socialMedias={socialMedias} />
    </Layout>
  )
}
const Anchor = styled.div`
  position:relative;
  top:-100px;
`

const StyledCardCol = styled(Col)`
  margin-bottom: 19px;
  & div {
    height: 100%;
    & div {
      justify-content: flex-end;
    }
    & a {
      align-self: center;
    }
  }
`

const StyledDevelopAwesomenessParagraph = styled(Paragraph)`
  text-align: center;
  ${media.md`
    text-align: left;
  `}
`

const StyledPeakContainer = styled(Row)`
  position: relative;
  padding-left: 0;
  margin-bottom: 160px;
  ${media.md`
    margin-bottom: 0;
    padding-left: 45px;
  `}
`

const PeakImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 75vw;
  a {
    width: 35%;
    padding: 7.5% 12px;
  }
  a > span {
    font-size: 8px;
    line-height: 1.5;
  }
  .gatsby-image-wrapper {
    width: 25%;
    margin: 10px;
    border-radius: 10px;
  }
  ${media.md`
    margin-left: -20px;
    a {
      padding: 0 37px;
    }
    a > span {
      font-size: 27px;
    }
  `}
  ${media.lg`
    a {
      padding: 4% 37px;
    }
  `}
  ${media.xl`
    a {
      padding: 5% 37px;
    }
  `}
`

const StyledCard = styled.div`
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  border-radius: 10px;
  transition: all 500ms;
  &:hover {
    transform: translateY(-4px);
    box-shadow: rgb(0 0 0 / 20%) 8px 8px 7px 5px;
  }
`

const BlackBackground = styled.div`
  background: ${Colors.GREY_1};
  width: 30%;
  right: 0;
  bottom: -30%;
  height: 95%;
  border-radius: 10px;
  position: absolute;
  ${media.md`
    right: 5%;
    top: 0;
  `}
`

const JobCardCol = styled(Col)`
  margin-top: 50px;
  ${media.lg`
    margin-top: 0;
  `}
`

const ContainerWithVerticalMargin = styled(Container)`
  margin: 71px auto;
  ${media.md`
    margin-top: ${props => (props.top ? "230px" : "auto")};
    margin-bottom: ${props => (props.bottom ? "230px" : "auto")};
  `}
`

const StyledTopImage = styled(Container)`
  position: relative;
  padding: 0;
  div:first-of-type {
    height: 25vh;
    ${media.md`
      height: 50vh;
    `}
    ${media.lg`
      height: calc(100vh - 64px);
    `}
  }
  div:nth-child(2) {
    position: absolute !important;
    top: -60px;
    width: 100%;
  }
`

const StyledHeaderContainer = styled(Container)`
  ${media.md`
    & > div:first-of-type {
      margin-top: -80px;
    }
  `}
`

const StyledTopParagraph = styled(Container)`
  margin-top: 30px;
  ${media.md`
    margin-top: 100px;
  `}
`
const StyledLink = styled.a`
  text-decoration: none;
`

export default Careers

export const careersQuery = graphql`
  query CareersQuery(
    $page: ContentfulPageFilterListInput = {
      elemMatch: { title: { eq: "Careers" } }
    }
    $linkFilter: ContentfulLinkFilterInput = { title: { regex: "/careers/" } }
  ) {
    links: allContentfulLink(filter: $linkFilter) {
      nodes {
        title
        content
        url
      }
    }

    topCards: allContentfulCard(
      filter: { page: $page }
      limit: 4
      sort: { fields: order }
    ) {
      nodes {
        ...card
      }
    }
    galleryInPage: allContentfulGallery(
      filter: { page: $page }
      sort: { fields: order }
    ) {
      nodes {
        ...gallery
        media {
          localFile {
            childImageSharp {
              fluid(toFormat: WEBP, maxWidth: 3080, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              mobileImage: fixed(toFormat: WEBP, width: 100, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              tabletImage: fixed(toFormat: WEBP, width: 145, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              desktopImage: fixed(toFormat: WEBP, width: 260, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
          file {
            url
          }
          title
        }
      }
    }
    postInPage: allContentfulPost(
      filter: { page: $page }
      sort: { fields: order }
    ) {
      nodes {
        ...post
        thumbnail {
          localFile {
            childImageSharp {
              fluid(toFormat: WEBP, maxWidth: 900, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
              mobileImage: fixed(toFormat: WEBP, width: 250, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              tabletImage: fixed(toFormat: WEBP, width: 300, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
              desktopImage: fixed(toFormat: WEBP, width: 450, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
    benefit: allContentfulMediaObjectGrid(filter: { page: $page }) {
      nodes {
        ...mediaObjectGrid
      }
    }
    socialMedias: allContentfulSocialMediaGroup {
      nodes {
        ...socialMedia
      }
    }
    reviewInPage: allContentfulReviewBlock(filter: { page: $page }) {
      nodes {
        title
        reviews {
          title
          slug
          author
          thumbnail {
            localFile {
              childImageSharp {
                fluid(toFormat: WEBP, quality: 100) {
                  ...imageSharpFluid
                }
                mobileImage: fixed(
                  toFormat: WEBP
                  width: 220
                  height: 220
                  quality: 100
                ) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                tabletImage: fixed(
                  toFormat: WEBP
                  width: 250
                  height: 250
                  quality: 100
                ) {
                  ...GatsbyImageSharpFixed_withWebp
                }
                desktopImage: fixed(
                  toFormat: WEBP
                  width: 300
                  height: 300
                  quality: 100
                ) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
            file {
              url
            }
          }
          content {
            content
          }
        }
      }
    }
    jobOpportunity: allContentfulJobList(filter: { page: $page }) {
      nodes {
        ...jobOpportunity
      }
    }
  }
`
